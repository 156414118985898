'use client';
import { useContext } from 'react';
import { AuthContext } from '@app/context/auth';
import { BaseLayout } from '@app/components/layouts';
import { News } from '@app/views';

export default function Home() {
  // context
  const [auth, setAuth] = useContext(AuthContext);
  return (
    <BaseLayout>
      <News />
    </BaseLayout>
  );
}
