import Link from 'next/link';
import cn from 'classnames';
import Image from 'next/image';
import styles from './News.module.css';

const News = () => {
  return (
    <div className="md:mx-10 2xl:mx-40 mt-4">
      <h1 className="text-3xl font-bold">Cryptocurrency News</h1>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2024-07-25</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-04-08_22-46-10_6095.webp"
            alt={`A Surge in Popularity: MEW, WIF, and Solana Memecoins Amid Network Activity`}
            width={300}
            height={300}
            loading="lazy"
            className="xl:ml-3 my-3 xl:float-right"
          />
          In the dynamic world of cryptocurrency, three notable entities have
          recently experienced a significant surge in popularity: MyEtherWallet
          (MEW), Wallet Import Format (WIF), and Solana memecoins. This rise can
          be attributed to the increasing network activity within the
          cryptocurrency ecosystem.
          <br />
          <Link
            href="/news/a-surge-in-popularity-mew-wif-and-solana-memecoins-amid-network-activity/"
            className="link"
          >
            A Surge in Popularity: MEW, WIF, and Solana Memecoins Amid Network
            Activity
          </Link>
        </p>
      </div>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2024-05-29</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-04-08_21-12-11_6873.webp"
            alt={`Crypto Protocols unleash AI Power to push boundaries of DeFi`}
            width={300}
            height={300}
            loading="lazy"
            className="xl:ml-3 my-3 xl:float-right"
          />
          Crypto protocols are increasingly leveraging the power of artificial
          intelligence (AI) to push the boundaries of decentralized finance
          (DeFi) and unlock new use cases. The integration of AI and DeFi
          presents exciting possibilities.
          <br />
          <Link
            href="/news/crypto-protocols-unleash-ai-power-to-push-boundaries-of-defi/"
            className="link"
          >
            Crypto Protocols unleash AI Power to push boundaries of DeFi
          </Link>
        </p>
      </div>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2024-05-29</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-04-08_19-35-09_4835.webp"
            alt={`DeFi markets surge in 2024 as capital inflows drive growth`}
            width={300}
            height={300}
            loading="lazy"
            className="xl:ml-3 my-3 xl:float-right"
          />
          According to the search results, DeFi markets have seen a significant
          surge in capital inflows during 2024.
          <br />
          <Link
            href="/news/defi-markets-surge-in-2024-as-capital-inflows-drive-growth/"
            className="link"
          >
            DeFi markets surge in 2024 as capital inflows drive growth
          </Link>
        </p>
      </div>
    </div>
  );
};

export default News;
